import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"  viewBox="0 0 1064.000000 1064.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd"  transform="translate(0.000000,1064.000000) scale(0.100000,-0.100000)">

<path d="M9715 6280 c3 -5 14 -10 23 -10 15 0 15 2 2 10 -20 13 -33 13 -25 0z"/>
<path d="M9760 6273 c0 -3 8 -11 17 -18 14 -11 10 -12 -27 -9 -35 3 -41 1 -29
-9 8 -6 12 -11 10 -10 -2 1 -13 -4 -25 -12 -11 -8 -29 -15 -39 -15 -9 0 -21
-8 -24 -17 -4 -10 -11 -21 -17 -25 -5 -3 8 -9 30 -13 21 -4 75 -15 119 -25
111 -25 648 -120 652 -116 11 11 -129 80 -261 129 -247 91 -406 146 -406 140z
m-3 -129 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M8920 5900 c-13 -8 -12 -10 3 -10 9 0 17 5 17 10 0 12 -1 12 -20 0z"/>
<path d="M8628 5650 c32 -13 28 -15 -25 -13 -41 2 -44 1 -18 -6 29 -8 29 -9 4
-10 -31 -1 -89 -29 -89 -42 0 -5 -7 -9 -15 -9 -19 0 -74 -27 -92 -45 -10 -11
-10 -14 0 -18 7 -3 5 -6 -5 -6 -9 -1 -20 4 -23 9 -9 14 -25 12 -25 -3 0 -10
35 -19 117 -31 134 -20 185 -38 213 -74 28 -35 27 -109 -3 -153 -42 -62 -50
-63 -310 -34 -127 14 -248 28 -267 31 -61 9 -226 14 -200 6 l25 -8 -25 -8
c-14 -5 -30 -8 -36 -7 -6 1 -17 -6 -24 -14 -7 -8 -25 -15 -41 -15 -22 0 -29
-5 -29 -20 0 -15 -7 -20 -25 -20 -14 0 -33 -8 -44 -19 -10 -10 -31 -23 -47
-29 l-29 -10 40 -3 40 -2 -35 -7 c-19 -4 -30 -8 -25 -9 159 -21 380 -48 628
-77 332 -38 369 -38 454 2 49 24 119 98 151 158 20 40 27 70 30 137 6 115 -13
171 -81 246 -52 56 -150 114 -192 112 -18 0 -18 -1 3 -9z m-151 -156 c-3 -3
-12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-524 -251 c-7 -2 -19 -2 -25
0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-36 -29 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z"/>
<path d="M8283 5503 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M7568 5093 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M7632 4968 c-5 -7 -12 -26 -16 -43 -9 -42 -55 -399 -52 -402 1 -1 17
-4 36 -5 l34 -3 17 142 c10 77 19 152 21 165 2 18 26 -9 123 -142 73 -100 127
-165 137 -165 9 0 85 58 168 128 83 71 154 127 156 124 4 -4 -18 -206 -31
-279 -4 -27 -3 -28 35 -28 25 0 40 5 40 13 0 6 11 99 25 205 13 106 22 199 19
206 -17 46 -50 26 -264 -163 -69 -61 -129 -110 -135 -110 -5 0 -64 75 -130
166 -155 214 -160 219 -183 191z"/>
<path d="M7317 4938 c4 -31 4 -32 -11 -13 -15 20 -15 20 -16 -2 0 -13 -4 -23
-8 -23 -5 0 -14 -50 -21 -112 -12 -115 -20 -142 -45 -151 -8 -3 -113 3 -233
13 -216 19 -310 23 -223 10 l45 -7 -47 -1 c-27 -1 -48 -7 -48 -13 0 -5 4 -8 9
-4 5 3 12 1 16 -5 3 -6 -4 -10 -16 -10 -12 0 -20 -2 -18 -5 8 -8 413 -55 474
-55 107 0 129 30 150 206 16 124 14 187 -5 199 -4 3 -6 -10 -3 -27z"/>
<path d="M7262 4946 c7 -8 15 -12 17 -11 5 6 -10 25 -20 25 -5 0 -4 -6 3 -14z"/>
<path d="M6665 4630 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M6413 4523 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M6425 4510 c-75 -12 -96 -22 -32 -15 130 14 143 14 112 6 -16 -5 -45
-9 -64 -10 -19 0 -52 -10 -75 -20 -23 -10 -60 -27 -83 -36 -23 -10 -41 -21
-39 -24 2 -3 -15 -7 -37 -9 -39 -2 -158 -55 -165 -74 -2 -4 -18 -8 -35 -8 -18
0 -41 -7 -51 -15 -11 -8 -26 -15 -33 -15 -7 0 -46 -15 -86 -34 -80 -37 -141
-62 -174 -71 -13 -4 -23 -11 -23 -16 0 -5 -15 -9 -32 -9 -18 0 -42 -7 -52 -15
-11 -8 -26 -15 -33 -15 -7 0 -46 -15 -86 -33 -129 -60 -148 -67 -158 -61 -5 3
-22 -2 -37 -12 -54 -36 -113 -65 -122 -59 -12 7 -95 -20 -129 -43 -42 -28
-102 -53 -112 -46 -13 8 -60 -23 -56 -38 2 -13 -69 -31 -95 -24 -11 2 -27 -7
-42 -25 -24 -28 -56 -31 -56 -5 0 6 -7 3 -14 -6 -8 -11 -27 -18 -47 -19 -19 0
-28 -3 -21 -6 16 -6 -3 -33 -23 -33 -16 0 -27 -50 -11 -50 6 0 56 -7 111 -15
499 -69 1476 -195 1515 -195 24 0 27 7 57 113 17 61 56 199 87 305 31 107 56
200 56 208 0 10 13 14 48 13 63 0 211 -15 217 -21 3 -2 -6 -42 -19 -89 -26
-92 -146 -546 -146 -553 0 -6 40 -14 130 -26 47 -5 254 -32 460 -59 206 -27
376 -48 378 -47 1 2 78 171 172 377 l169 374 136 3 137 3 -8 -23 c-9 -30 -293
-727 -305 -749 -5 -11 -5 -20 0 -24 23 -14 909 -112 932 -103 18 7 156 207
281 406 74 117 175 311 227 435 29 69 65 102 134 122 29 8 214 30 412 50 271
26 376 41 427 57 37 12 66 24 64 26 -2 1 -56 7 -120 12 -64 6 -169 14 -233 20
-159 13 -882 63 -1468 100 -610 39 -878 57 -1035 70 -623 52 -820 63 -905 50z
m2449 -657 c-58 -134 -203 -363 -291 -462 l-39 -44 -269 37 c-148 20 -272 39
-276 42 -11 6 13 87 61 204 23 58 54 134 69 170 20 49 34 68 56 79 29 14 248
25 587 29 l128 2 -26 -57z"/>
<path d="M1301 4494 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8567 4504 c-4 -4 -7 -22 -7 -41 0 -31 2 -33 34 -33 30 0 35 4 41 31
4 17 5 32 3 34 -9 8 -65 15 -71 9z"/>
<path d="M4218 3713 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4318 3703 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M3965 3328 c22 -453 147 -974 308 -1283 79 -150 122 -211 217 -306
175 -175 347 -210 550 -113 251 120 541 481 780 969 124 254 255 605 226 605
-8 0 -1058 129 -1136 140 -41 6 -194 24 -340 40 -146 17 -278 33 -295 35 -16
3 -55 8 -85 10 -30 3 -84 9 -119 15 -46 7 -68 7 -77 -1 -10 -8 -14 -8 -14 0 0
6 -5 11 -11 11 -7 0 -8 -39 -4 -122z m22 10 c-3 -8 -6 -5 -6 6 -1 11 2 17 5
13 3 -3 4 -12 1 -19z m1692 -513 c-27 -145 -191 -482 -328 -675 -65 -91 -84
-114 -181 -210 -112 -112 -208 -163 -315 -168 -61 -3 -80 0 -126 22 -103 49
-186 148 -285 343 -68 132 -99 223 -110 317 l-7 59 34 -7 c88 -17 286 -18 393
-2 343 51 700 193 881 350 22 19 43 31 47 28 4 -4 2 -29 -3 -57z"/>
<path d="M3690 3290 c-1 -25 -4 -37 -7 -27 -6 24 -16 21 -41 -13 -27 -36 -42
-38 -42 -7 -1 21 -2 20 -30 -10 -16 -18 -37 -33 -47 -33 -9 0 -29 -15 -44 -32
-20 -23 -31 -29 -37 -21 -5 9 -17 4 -39 -17 -18 -17 -36 -30 -42 -30 -5 0 -20
-13 -33 -29 -16 -19 -34 -28 -52 -28 -17 0 -33 -9 -42 -22 -8 -11 -21 -21 -28
-21 -7 0 -21 -9 -31 -20 -10 -11 -28 -20 -40 -20 -26 0 -36 -17 -29 -52 2 -12
1 -16 -2 -8 -9 21 -44 64 -44 55 0 -11 60 -122 111 -205 228 -369 520 -695
769 -856 83 -54 177 -100 185 -92 7 7 -37 124 -124 331 -120 285 -167 442
-235 787 -29 149 -43 225 -69 390 -3 15 -5 7 -7 -20z m-533 -416 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M6333 3143 c-3 -10 -26 -67 -50 -128 -25 -60 -63 -155 -85 -210 -64
-163 -169 -395 -212 -470 -35 -60 -91 -143 -283 -417 -78 -112 -101 -147 -141
-210 -40 -65 -41 -68 -20 -68 13 0 54 9 92 20 339 97 763 383 1136 766 144
149 210 226 312 369 65 91 154 243 146 250 -2 3 -188 27 -414 55 -225 27 -424
52 -442 55 -25 5 -34 2 -39 -12z"/>
<path d="M7447 2867 c-144 -214 -397 -547 -477 -627 -3 -3 -30 -32 -60 -65
-98 -106 -212 -205 -370 -321 -188 -138 -242 -184 -214 -184 53 0 297 69 458
130 411 155 932 457 1284 743 129 105 347 336 331 351 -5 4 -296 44 -599 81
-113 13 -217 27 -231 29 -25 5 -34 -6 -122 -137z"/>
<path d="M2781 2814 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2810 2780 c0 -9 7 -25 15 -37 13 -18 13 -22 0 -27 -8 -3 -17 0 -21
6 -4 7 -3 8 4 4 15 -9 15 -3 0 29 l-11 25 -23 -21 c-13 -12 -21 -26 -18 -30 3
-5 -11 -8 -31 -7 -23 1 -40 -5 -48 -16 -7 -9 -23 -22 -36 -29 -13 -7 -23 -16
-22 -20 1 -5 -8 -17 -20 -28 -21 -18 -23 -18 -41 -2 -10 9 -21 13 -25 10 -3
-4 -2 -7 4 -7 6 0 13 -7 17 -15 4 -13 2 -13 -14 -5 -10 5 -22 15 -25 20 -3 6
-11 10 -17 10 -11 0 11 -21 151 -145 130 -115 352 -277 477 -348 105 -59 205
-109 210 -105 2 2 -18 31 -44 64 -150 186 -381 514 -459 650 -12 22 -22 32
-23 24z"/>
</g>

</svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
